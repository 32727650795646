import { useDispatch, useSelector } from 'react-redux';
import {
    addClientContactAction,
    editClientContactAction,
    getClientContactsAction
} from "../Redux/Slices/ClientContacts/ClientContacts.slice";

export const useClientContacts = (): any => {

    const clientContactsData = useSelector((state: any) => state.clientContacts.clients);
    const lastAddedClientContact = useSelector((state: any) => state.clientContacts.lastAddedClientContact);

    const dispatch = useDispatch();

    // Get Client Contacts
    const getClientContacts = (clientId: number, HMSProjectId: string) => {
        dispatch(getClientContactsAction([clientId, HMSProjectId]));
    };

    // Add Client Contact
    const addClientContact = (clientId: number, practitionerID: string, reason: number, notes: string) => {
        const utcString = new Date().toISOString();

        dispatch(addClientContactAction([{
            "clientID": clientId,
            "practitionerID": practitionerID,
            "startDateTime": utcString,
            "contactReason": reason,
            "notes": notes,
            "contactSource": "HMS",
        }]));
    };

    // Add Full Client Contact
    const addFullClientContact = (clientId: number, practitionerID: string, reason: number, minutes: number, notes: string) => {
        const now = new Date();
        const startDate = new Date(now.getTime() - minutes * 60000);
        const startUTCString = startDate.toISOString();
        const endUTCString = now.toISOString();


        dispatch(addClientContactAction([{
            "clientID": clientId,
            "practitionerID": practitionerID,
            "startDateTime": startUTCString,
            "endDateTime": endUTCString,
            "durationInSeconds": minutes * 60,
            "contactReason": reason,
            "notes": notes,
            "contactSource": "HMS",
        }]));
    };

    // Edit Client Contact
    const updateClientContact = (clientId: number, notes: string, duration?: number) => {
        console.log('lastAddedClientContact', lastAddedClientContact);
        if(!lastAddedClientContact) return false;
        // if(!lastAddedClientContact || lastAddedClientContact.clientID != clientId) return false;

        const utcString = new Date().toISOString();
        const endDateTime = (lastAddedClientContact.endDateTime && lastAddedClientContact.endDateTime != "0001-01-01T00:00:00") ? lastAddedClientContact.endDateTime : utcString;

        dispatch(editClientContactAction([{
            ...lastAddedClientContact,
            "endDateTime": endDateTime,
            "durationInSeconds": duration || lastAddedClientContact.durationInSeconds,
            "notes": notes,
        }]));
    };

    return {
        getClientContacts, addClientContact, updateClientContact,
        addFullClientContact,
        clientContactsData
    };
}