import {
	ComplexFilterOptionSelectTypeLocal,
	ComplexFilterParamSelectTypeLocal,
	ComplexFilterParamTypeLocal,
	filterDataParams,
	filterTypes
} from "./filter.data";
import {
	ComplexFilterChangeType, ComplexFilterParamConditionsChangeType, ComplexFilterParamDateChangeType,
	ComplexFilterParamSelectChangeType,
	ComplexFilterParamType,
	ComplexFilterType
} from "lavaa";
import {PatientListTypeFilterByType, PatientListTypeFilterType} from "./PatientList.type";
import {MethodsType} from "../../DataTable/DataTable.types";

export function prepareFilter(headers: any){
	const filterData: ComplexFilterType = [[]];

	headers.map((header: any) => {
		const key = header.columnName.toLowerCase();

		if(filterDataParams.hasOwnProperty(key)){
			filterData[0].push(filterDataParams[key]);
		}
	});

	console.log(filterData);
	return filterData;
}

export function handleTableAction(actionType: string, data: any, filter: PatientListTypeFilterType, headers: any[]): PatientListTypeFilterType | null{
	let updated = false;
	let isFilterChanged = false;
	let updatedFilter = {...filter};

	// Pagination Changed
	if (actionType === 'paginationChanged') {
		const { pageNumber, pageSize } = data;
		updated = true;
		updatedFilter = {...updatedFilter, page: pageNumber, pageSize: pageSize};
	}

	// Filter Changed
	if (actionType === 'filterChanged') {
		const tableFilterBy: any[] = Object.keys(data).map((key: string) => {
			const field = data[key];
			const header = headers.find((header: any) => header.field === key);
			if(!header) return null;

			const columnName = header.columnName;

			return {
				filterField: columnName,
				filteredByField: columnName,
				filterType: filterTypes[field.type],
				filterTerm: field.filter,
				filterFrom: null,
				filterTo: null,
				filterFieldType: 'table'
			};
		});

		updatedFilter.filterBy = updatedFilter.filterBy.filter((filterParam: PatientListTypeFilterByType) => {
			// Remove table filters
			if(filterParam.filterFieldType === 'table') return false;


			// Remove filters that are in the table
			const field = filterParam.filterField;
			return !tableFilterBy.some((filterParamNew: any) => {
				return field === filterParamNew.filterField;
			});
		});

		updated = true;
		isFilterChanged = true;

		// Merge table filters with the existing filters
		updatedFilter = {
			...updatedFilter,
			filterBy: [...updatedFilter.filterBy, ...tableFilterBy],
		};
	}

	// Sort Changed
	if (actionType === 'sortChanged') {
		updated = true;

		const key = data ? data.colId : "";
		const header = headers.find((header: any) => header.field === key);
		const columnName = header ? header.columnName : "";

		updatedFilter = {
			...updatedFilter,
			sorting: {
				...filter.sorting,
				sortedByField: columnName,
				isAscending: data ? data.sort === 'asc' : true
			}
		};
	}

	if (updated) {
		if (isFilterChanged) {
			updatedFilter = {...updatedFilter, page: 1};
		}

		return ({...updatedFilter});
	}

	return null;
}

export function handleComplexFilterChanged(data: ComplexFilterChangeType, filter: PatientListTypeFilterType, headers: any): PatientListTypeFilterType | null{
	let updatedFilter = {...filter};

	//Search Column in Headers
	const header = headers.find((header: any) => header.columnName.toLowerCase() === data.id.toLowerCase());
	if(!header) return null;

	const filterParams = filterDataParams[data.id.toLowerCase()];
	if(!filterParams) return null;

	const filterBy = prepareFilterChange(data, filter.filterBy, filterParams);

	return {...updatedFilter, filterBy, page: 1};
}

function prepareFilterChange(data: ComplexFilterChangeType, filterBy: PatientListTypeFilterByType[], filterParams: ComplexFilterParamType): PatientListTypeFilterByType[] {

	// Check if the param is unselected
	const [isUnselected, onceUse, filterParamId] = isUnselect(data, filterParams);

	// Remove the param from the filter
	if(isUnselected){
		 return filterBy.filter((filterParam: PatientListTypeFilterByType) => {
			if(onceUse){
				return filterParam.filterField !== data.id;
			}
			else return !(filterParam.filterField == data.id && filterParam.filterParamId == filterParamId);
		});
	}

	// Check if the param can be used only once
	const filterByParam = prepareChangeByType(data, filterParams as ComplexFilterParamTypeLocal);
	if(!filterByParam) return filterBy;

	let updatedFilterBy: PatientListTypeFilterByType[] = [...filterBy];

	// Remove the param from the filter if it can be used only once
	if(onceUse) {
		updatedFilterBy = updatedFilterBy.filter((filterParam: PatientListTypeFilterByType) => {
			console.log(filterParam.filterField, data.id, filterParam.filterField !== data.id);
			return filterParam.filterField !== data.id;
		});
	}

	// Add the param to the filter
	if(Array.isArray(filterByParam)){
		filterByParam.forEach((param) => {
			updatedFilterBy.push({...param, filterFieldType: 'filter'});
		});
	}
	else{
		updatedFilterBy.push({...filterByParam, filterFieldType: 'filter'});
	}

	return updatedFilterBy;
}

function isUnselect(data: ComplexFilterChangeType, filterParams: ComplexFilterParamType): [boolean, boolean, any] {
	switch (filterParams.type) {
		case 'select':
			const changeDataSelect = data as ComplexFilterChangeType & ComplexFilterParamSelectChangeType;
			return [!changeDataSelect.selected, true, changeDataSelect.optionId];
		case "multiselect":
			const changeDataMultiSelect = data as ComplexFilterChangeType & ComplexFilterParamSelectChangeType;
			return [!changeDataMultiSelect.selected, false, changeDataMultiSelect.optionId];
		case "conditions":
			const changeDataConditions = data as ComplexFilterChangeType & ComplexFilterParamConditionsChangeType;
			return [changeDataConditions.comparison === undefined, true, undefined];
		case "date":
			const changeDataDate = data as ComplexFilterChangeType & ComplexFilterParamDateChangeType;
			return [changeDataDate.startDate === null && changeDataDate.endDate === null, true, undefined]
		default:
			return [true, true, undefined];
	}
}

function prepareChangeByType(data: ComplexFilterChangeType, filterParams: ComplexFilterParamTypeLocal): PatientListTypeFilterByType | PatientListTypeFilterByType[] | undefined {
	switch (filterParams.type) {
		case 'select':
			return prepareChangeSelect(data as ComplexFilterChangeType & ComplexFilterParamSelectChangeType, filterParams);
		case 'multiselect':
			return prepareChangeSelect(data as ComplexFilterChangeType & ComplexFilterParamSelectChangeType, filterParams);
		// case 'conditions':
		// 	return prepareChangeConditions(data, filterBy, filterParams);
		// case 'date':
		// 	return prepareChangeDate(data, filterBy, filterParams);
		default:
			return undefined;
	}
}

function prepareChangeSelect(
	data: ComplexFilterChangeType & ComplexFilterParamSelectChangeType,
	filterParams: ComplexFilterParamType & ComplexFilterParamSelectTypeLocal
): PatientListTypeFilterByType | PatientListTypeFilterByType[] | undefined {
	const optionId = data.optionId;
	const parameter: ComplexFilterOptionSelectTypeLocal = filterParams.options.find((option) => option.id === optionId) as ComplexFilterOptionSelectTypeLocal;

	if (!parameter) return undefined;
	return parameter.filter;
}

export function resetQuickFiltersOnChangeComplexFilter(data: ComplexFilterChangeType, methods: MethodsType | null) {
	if(!methods) return;

	const id = data.id.toLowerCase();

	setTimeout(() => {
		methods.resetFilterCol(id);
	}, 300);
}

export function resetComplexFilterOnChangeQuickFilter(actionType: string, data: any, headers: any, complexFilterData: ComplexFilterType) {
	if (actionType !== 'filterChanged') return;
	console.log(actionType, data, headers, complexFilterData);

	let columnNames: string[] = [];

	Object.keys(data).forEach((key: string) => {
		const header = headers.find((header: any) => header.field === key);
		if(!header) return null;

		const columnName = header.columnName;
		columnNames.push(columnName);
	});

	const newComplexFilterData: ComplexFilterType = [[]];

	newComplexFilterData[0] = complexFilterData[0].map((filterParam: ComplexFilterParamType) => {
		if(columnNames.includes(filterParam.id)){
			return filterDataParams[filterParam.id.toLowerCase()];
		}
		return filterParam;
	});

	return newComplexFilterData;
}