import { FC, memo, useEffect, useRef, useState, useContext } from "react";
import { addSnackbar, Col, Container, Icon, iconsTypes, Modal, Row, Text, useLocalStorage } from "lavaa";
import { DataTable } from "../../../../DataTable/DataTable.com";
import { noop } from "../../../../../Tools/Noop";
import { useReports } from "../../../../../Hooks/UseReports";
import { useAccount } from "../../../../../Hooks/UseAccount";
import { useParams } from "react-router-dom";
import { ReportFilterType } from "../../../Reports.types";
import { useMixPanel } from "../../../../../Hooks/UseMixPanel";
import { copyToClipboard } from "../../../../../Tools/clipboard";
import css from "./QMDiabetis.module.scss";
import { ModalsCtx } from "../../../../../Context/Modals.context";
import { ReportClientInfo } from "../../../../Custom/Modals/ReportClientInfo/ReportClientInfo.com";

type Props = {
    setFilteredHeaders: (headers: any[]) => void;
    filteredHeaders: any[];
    filteredHeadersLastUpdate: number;
    onFilterUpdate: (data: any) => void;
    attributedFilter: any[];
};

// QMDiabetis Component
export const QMDiabetis: FC<Props> = memo((props) => {

    // Props
    const { setFilteredHeaders, filteredHeaders, filteredHeadersLastUpdate, onFilterUpdate, attributedFilter } = props;

    // Constants
    const reportName = 'ReportQMDiabetis';
    const filterTypes: any = {
        contains: 0,
        DateRange: 1,
        NumberRange: 2,
        startsWith: 3,
        endsWith: 4,
        equals: 5,
        notEqual: 6,
        notContains: 7,
        blank: 8,
        notBlank: 9
    };

    // public enum FilterType
    // {
    //     TextContain = 0,
    //     DateRange = 1,
    //     NumberRange = 2,
    //     TextStartWith = 3,
    //     TextEndWith = 4,
    //     Equal = 5,
    //     NotEqual = 6,
    //     NotContain = 7,
    //     Blank = 8,
    //     NotBlank = 9
    // }

    // Context
    const { setReportClientInfoId, setReportSourceClientInfoId, setReportClientInfoModalActive } = useContext(ModalsCtx);

    // Hooks
    const [ tableConfig, setTableConfig ] = useLocalStorage<Record<string, any>>(reportName, {});
    
    const { requestReports, getReport, getClientDetailsById, clientDetails, clientDetailsLastUpdate, clearClientDetails } = useReports();
    const { HMSProjectId, account } = useAccount();
    const { reportType = 'qrda' } = useParams();
    const reportData = getReport(reportName);
    const { track } = useMixPanel();

    // Refs 
    const isTableInited = useRef(false);

    // States
    const [clientDetailsId, setClientDetailsId] = useState<number>(0);
    
    const [reload, setReload] = useState(0);
    const [headers, setHeaders] = useState([]);
    const [reset, setReset] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [columnModel, setColumnModel] = useState([
        {
            field: 'PatientIdFullName', 
            headerName: 'Patient', 
            pinned: true, 
            width: 250, 
            renderer: [
                'PatientCellRenderer', 
                'ClientDetailsCellRenderer'
            ], 
            // setClientDetailsId: (clientId: number) => {setClientDetailsId(clientId); track('Get Client ID', {report: 'QMDiabetis'});}, 
            setClientDetailsId: handleOpenClientDetails,
            comparator: noop, /*floatingFilterComponent: PatientFilter*/
        },
        {field: 'G2081', headerName: 'G2081', renderer: [{name: 'bool', props: {useXFalse: true}}]},
        {field: 'G2090', headerName: 'G2090', renderer: [{name: 'bool', props: {useXFalse: true}}]},
        {field: 'G2091', headerName: 'G2091', renderer: [{name: 'bool', props: {useXFalse: true}}]},
        {field: 'G9687', headerName: 'G9687', renderer: [{name: 'bool', props: {useXFalse: true}}]},
        {field: 'G9988', headerName: 'G9988', renderer: [{name: 'bool', props: {useXFalse: true}}]},
        {field: 'Measure1', renderer: ['measure']},
        {field: 'IsActive', headerName: 'Active', renderer: [{name: 'bool', props: {useXFalse: true}}]},
    ]);

    // Table Filter
    const [filter, setFilter] = useState<ReportFilterType>({
        page: 1,
        pageSize: 10,
        search: [],
        sort: {
            sortedByField: '',
            isAscending: true,
            sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
        },
    });

    // Handle Table Data Changed
    const handleTableDataChanged = (data: any = {}) => {
        const { headers = [], rows = [], totalPages = 1  } = data;
        
        // Prepare Headers
        const indexedHeaders = headers
        .filter((row: any) => row.isShownInAppReport)
        .map((header: any) => {
            return {
                field: header.columnName, 
                headerName: header.displayName, 
                index: header.index,
                checked: header.isShownByDefault
            };
        })
        .sort((a: any, b: any) => a.index - b.index);
        
        // Prepare Column Model
        const updatedColumnModel = indexedHeaders.map((header: any) => {
            const { field, headerName } = header;
            const colModel = columnModel.find((col: any) => col.field === field);
            return colModel ? {...colModel, headerTooltip: headerName} : {field, headerTooltip: headerName};
        });
        
        setColumnModel(updatedColumnModel);
        setTotalPages(totalPages);
        setHeaders(indexedHeaders);
        setRows(rows);

        // Set filtered headers
        if (filteredHeaders.length === 0 && indexedHeaders.length > 0) {
            setFilteredHeaders(indexedHeaders);
            setReset(performance.now());
        }
    };

    // Handle Action
    const handleAction = (actionType: string, data: any) => {
        let updated = false;
        let isFilterChanged = false;
        let updatedFilter = {...filter};

        // Pagination Changed
        if (actionType === 'paginationChanged') {
            const { pageNumber, pageSize } = data;
            updated = true;
            updatedFilter = {...updatedFilter, page: pageNumber, pageSize: pageSize};
        }

        // Filter Changed
        if (actionType === 'filterChanged') {
            const fields: string[] = [];
            const search: any[] = Object.keys(data).map((key: string) => {
                const field = data[key];
                fields.push(field);

                return {
                    filteredByField: key,
                    filterType: filterTypes[field.type],
                    filterTerm: field.filter,
                    filterFrom: null,
                    filterTo: null
                };
            });
            
            updated = true;
            isFilterChanged = true;
            updatedFilter = {...updatedFilter, search};

            // Track Filtering
            track('Filtering', {report: reportName, action: 'filtering', columns: Object.keys(data).join(', ')});
        }
        
        // Sort Changed
        if (actionType === 'sortChanged') {
            updated = true;
            updatedFilter = {
                ...updatedFilter, 
                sort: {
                    ...filter.sort,
                    sortedByField: data ? data.colId : '',
                    isAscending: data ? data.sort === 'asc' : true
                }
            };

            // Track Sorting
            data && track('Sorting', {report: reportName, action: 'sorting', columnName: data.colId});
        }

        // Update Filter
        if (updated) {
            if (isFilterChanged == true) {
                updatedFilter = {...updatedFilter, page: 1};
            }

            setFilter({...updatedFilter});
            onFilterUpdate({...updatedFilter});
        }
    };

    // Handle Client Details Modal Close
    const handleClientDetailsModalClose = () => {
        clearClientDetails();
        setClientDetailsId(0);
        // setClientDetailsModalActive(false);
    };

    // Handle Open Client Details
    function handleOpenClientDetails (clientId: number, sourceClientId: string) {
        console.log('HANDLE', clientId, sourceClientId);
        setReportClientInfoId(clientId);
        setReportSourceClientInfoId(sourceClientId);
        setReportClientInfoModalActive(true);
        
        track('Get Client ID', {report: 'QMDiabetis'});
    };
    
    // Watch HMSProjectId
    useEffect(() => {
        requestReports(HMSProjectId, reportName, filter.sort, [...filter.search, ...attributedFilter], filter.page, filter.pageSize);
    }, [HMSProjectId, reportName, filter, attributedFilter]);

    // Watch Report Data
    useEffect(() => {
        if (reportData?.lastUpdated) {
            handleTableDataChanged(reportData);
        }
    }, [reportData?.lastUpdated]);

    // Watch HMSProjectId & clientDetailsId
    useEffect(() => {
        if (clientDetailsId && HMSProjectId) {
            // Open Modal
            // setClientDetailsModalActive(true);

            // Get Client Details
            getClientDetailsById(clientDetailsId, HMSProjectId);
        }
    }, [HMSProjectId, clientDetailsId]);

    return (
        <Container grow="1" className={css.DailyHuddleDischarge48h}>
            <DataTable 
                tableId={reportName}
                headers={filteredHeaders} 
                rows={rows} 
                columnModel={columnModel} 
                minColumnWidth={150} 
                totalPages={totalPages} 
                onAction={handleAction} 
                useServer={true}
                filteredHeadersLastUpdate={filteredHeadersLastUpdate}
            />

            {/* Client Details Modal */}
            <ReportClientInfo/>
        </Container>
    )
});