export const reportsOptions = [
    {
        id: 'generalReport',
        reportType: 'generalReport',
        name: 'Patient Care Prioritization Overview',
        index: 1,
        action: ['snooze', 'unsnooze', 'phone', 'mail'],
        columnModel: {
            snooze: {
                field: 'StartDateForSnooze', 
                headerTooltip: 'Snooze Patient', 
                filter: false, 
                renderer: 'button', 
                action: 'snoozePatient', 
                rendererOptions: {
                    text: 'Snooze Patient', 
                    type: 'secondary'
                }, 
                cellStyle: {
                    border: 'none'
                }
            }
        },
        category: null // null | dailyHuddle
    },
    {
        id: 'clinicalReport',
        reportType: 'ccmReport',
        name: 'Chronic Care Management',
        index: 2
    },
    {
        id: 'awvReport',
        reportType: 'awvReport',
        name: 'Annual Wellness Visit',
        index: 3
    },
    {
        id: 'dailyHuddle',
        reportType: 'dailyHuddle',
        name: 'Daily Huddle',
        index: 4
    },
    {
        id: 'tcm',
        reportType: 'tcm',
        name: 'Transitional Care Management',
        index: 5
    },
    {
        id: 'QRDA',
        reportType: 'qrda',
        name: 'Quality Reporting Document Architecture - QRDA',
        index: 6
    },
    {
        id: 'missingCodes',
        reportType: 'missingCodes',
        name: 'Missing Codes',
        index: 7
    },
    // {
    //     id: 'bigCalendar',
    //     reportType: "calendar",
    //     name: "Calendar"
    // }
];