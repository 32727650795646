import React, {FC} from 'react';
import css from '../Table.module.scss';
import {Button, Cell, iconsTypes} from "lavaa";

interface IProps {
	onClick?: (id: string, clientSourceId: string) => void,
	id: string,
	clientSourceId: string,
}

// CallCell
const CallCell: FC<IProps> = (props) => {

	const {onClick, id, clientSourceId} = props;

	function clickHandler(){
		if(onClick) onClick(id, clientSourceId);
	}

	return(
		<Cell alignitems="center" justifycontent="center" className={css.Cell}>
			<Button icon={iconsTypes.phoneOutline} iconSize="18" type="secondary" onClick={clickHandler} variant="text" className={css.CallBtn}/>
		</Cell>
	)
};

export {CallCell};