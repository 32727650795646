import React, {FC, useRef, useState} from "react";
import {Col, iconsTypes, Row, Text, Icon, addSnackbar} from 'lavaa';
import css from "./style.module.scss";
import {classNames} from "../../../../Tools";

interface IProps {
    onStart: () => void,
    onStop: (duration: number) => void,
    disabled: boolean,
    hasCreated: boolean
}

const timerDuration = 60 * 1000;

// Client Contact Timer
const ClientContactTimer: FC<IProps> = React.memo((props) => {
    const [timer, setTimer] = useState(0);
    const [timerStatus, setTimerStatus] = useState(false);
    const timerStatusRef = useRef(false);
    const {onStart, onStop, disabled, hasCreated} = props;

    function onButtonClick(){
        if(disabled) {
            if(!hasCreated){
                addSnackbar('warning', 'Select the Reason first', 'This is a required field');
            }
            return;
        }

        if(!timerStatusRef.current) {
            onStart();
            timerAnimation();
        }
        else{
            stopTimer();
        }
    }

    function stopTimer(){
        setTimerStatus(false);
        timerStatusRef.current = false;

        const seconds = Math.floor(timer / 1000);
        onStop(seconds);
    }

    function timerAnimation(){
        let start = performance.now();

        if(timer > 0){
            start = performance.now() - timer;
        }

        setTimerStatus(true);
        timerStatusRef.current = true;

        requestAnimationFrame(function animate(time) {
            const currTime = time - start;
            setTimer(currTime);

            if (timerStatusRef.current) {
                requestAnimationFrame(animate);
            }

        });
    }

    let timeFraction = (timer / timerDuration) % 100;
    let progress = timeFraction * 100 % 100;

    const radius = 45;
    const circle = Math.PI * (radius * 2);
    const strokeDashoffset = ((100 - progress) / 100) * circle;

    const timerHours = Math.floor(timer / 3600000).toString().padStart(2, '0');
    const timerMinutes = Math.floor(timer / 60000).toString().padStart(2, '0');
    const timerSeconds = Math.floor((timer % 60000) / 1000).toString().padStart(2, '0');


    const timerClasses = classNames(
        css.TimerButton,
        timerStatus ? css._active : "",
        disabled ? css._disabled : ""
    );

    return (
       <Col alignitems="center">
            <Row marginTop="1rem" marginBottom="0.5rem" style={disabled ? {opacity: '0.5'} : {}}>
                <Text size="x7">{timerHours}:{timerMinutes}:{timerSeconds}</Text>
            </Row>
            <Row>
                <Col className={timerClasses} onClick={onButtonClick}>
                    <Col alignitems="center" justifycontent="center">
                        <div className={css.progressCircle}>
                            <svg width="92" height="92" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <circle className={css.progressBar} r={radius} cx="46" cy="46" fill="transparent"
                                        strokeDasharray={circle} strokeDashoffset={`${strokeDashoffset}px`}/>
                            </svg>
                        </div>
                        {
                            timerStatus ? (
                                <>
                                    <Row className={css.Button} marginBottom="0.25rem">
                                        <Icon name={iconsTypes.stopBtn}/>
                                    </Row>
                                    <Text size="x3" bold={true}>Stop</Text>
                                </>

                            ) : (
                                <>
                                    <Row className={css.Button} marginBottom="0.25rem">
                                        <Icon name={iconsTypes.playBtn}/>
                                    </Row>
                                    <Text size="x3" bold={true}>Start</Text>
                                </>
                            )
                        }
                    </Col>
                </Col>
            </Row>
       </Col>
    )
});

export {ClientContactTimer};