import { createContext, FC, useState } from 'react';
import {useLocalStorage} from "../Hooks/UseLocalStorage";
import {MenuWidgets} from "../Components/Custom/Modals/MenuWidgets/type";
import {initialDashboardCardsView} from "../Components/Pages/Dashboard/Dashboard.data";

export const ModalsCtx = createContext<{
    [key: string]: any;
}>({});

export const ModalsContext: FC<any> = (props) => {

    const { children } = props;

    // States
    const [ isPatientLabResultModalActive, setPatientLabResultModalActive ] = useState(false);
    const [ patientLabResultModalData, setPatientLabResultModalData ] = useState({});
    const [ isModuleSettingsModalActive, setModuleSettingsModalActive ] = useState(false);
    const [ moduleSettingsUID, setModuleSettingsUID ] = useState('');
    const [ isEditPersonModalActive, setEditPersonModalActive ] = useState(false);
    const [ isPersonEditOutputModalActive, setPersonEditOutputModalActive ] = useState(false);
    const [ isMenuWidgetsModalActive, setMenuWidgetsModalActive  ] = useState(false);
    const [ menuWidgets, setMenuWidgets  ] = useLocalStorage<Array<MenuWidgets>>("menuWidgets", [MenuWidgets.HighRisk, MenuWidgets.LabResults]);
    const [ helpModal, setHelpModal ] = useState<boolean>(false);
    const [ isDiagnoseInfoModalActive, setDiagnoseInfoModalActive ] = useState(false);
    const [ diagnoseInfoModalData, setDiagnoseInfoModalData ] = useState({});
    const [ isMenuDashboardCardsModalActive, setMenuDashboardCardsModalActive  ] = useState(false);
    const [ menuDashboardCards, setMenuDashboardCards  ] = useLocalStorage<Array<string>>("menuDashboardCards", initialDashboardCardsView);
    const [ isReportClientInfoModalActive, setReportClientInfoModalActive  ] = useState(false);
    const [ reportClientInfoId, setReportClientInfoId  ] = useState<string>("");
    const [ reportSourceClientInfoId, setReportSourceClientInfoId  ] = useState<string>("");



    // Banch of states
    const context = {

        // Patient Lab Result Modal
        isPatientLabResultModalActive, 
        setPatientLabResultModalActive,
        patientLabResultModalData, 
        setPatientLabResultModalData,

        // Module Settings
        isModuleSettingsModalActive,
        setModuleSettingsModalActive,
        moduleSettingsUID,
        setModuleSettingsUID,

        // Edit Person
        isEditPersonModalActive,
        setEditPersonModalActive,

        // Output Person Edit
        isPersonEditOutputModalActive,
        setPersonEditOutputModalActive,

        // Menu Widgets
        isMenuWidgetsModalActive,
        setMenuWidgetsModalActive,
        menuWidgets,
        setMenuWidgets,

        // Help Modal
        helpModal,
        setHelpModal,

        // Diagnose Info Modal
        isDiagnoseInfoModalActive,
        setDiagnoseInfoModalActive,
        diagnoseInfoModalData,
        setDiagnoseInfoModalData,

        // Menu Dashboard Cards
        isMenuDashboardCardsModalActive,
        setMenuDashboardCardsModalActive,
        menuDashboardCards,
        setMenuDashboardCards,

        // ReportClientInfo
        isReportClientInfoModalActive,
        setReportClientInfoModalActive,
        reportClientInfoId,
        setReportClientInfoId,
        reportSourceClientInfoId,
        setReportSourceClientInfoId
    };

    return <ModalsCtx.Provider value={ {...context} }>
        { children }
    </ModalsCtx.Provider>
};