import { createSlice } from '@reduxjs/toolkit';

type ClientContactType = {
    clientContactID: number,
    clientID: number,
    practitionerID: string,
    startDateTime: Date,
    endDateTime: Date,
    durationInSeconds: number,
    contactReason: number,
    notes: string,
    deleteDate: Date,
    deletedBy: string,
    contactSource: string
}

interface IClientContactsTypeState {
    clients:  {
        [key: string]: ClientContactType[]
    },
    lastAddedClientContact: ClientContactType | undefined
}

const initialState:IClientContactsTypeState = {
    clients: {},
    lastAddedClientContact: undefined
};

export const clientContactsSlice = createSlice({
    name: 'ClientContacts',
    initialState,
    reducers: {

        // Get Client Contacts
        getClientContactsAction: (state, action) => {
            console.log('Get Client Contacts', action.payload);
        },

        // Receive Client Contacts
        receiveClientContactsAction: (state, action) => {
            console.log('Receive Client Contacts', action.payload);
            state.clients = action.payload;
        },

        // Add Client Contact
        addClientContactAction: (state, action) => {
            console.log('Add Client Contact', action.payload);
        },

        // Receive Add Client Contact
        receiveAddClientContactAction: (state, action) => {
            console.log('Receive Add Client Contact', action.payload);
            state.lastAddedClientContact = action.payload.data;
        },

        // Edit Client Contact
        editClientContactAction: (state, action) => {
            console.log('Edit Client Contact', action.payload);
        },

        // Receive Edit Client Contact
        receiveEditClientContactAction: (state, action) => {
            console.log('Receive Edit Client Contact', action.payload);
            state.lastAddedClientContact = action.payload.data;
        },
    }
});

export const {
    getClientContactsAction,
    receiveClientContactsAction,
    addClientContactAction,
    receiveAddClientContactAction,
    editClientContactAction,
    receiveEditClientContactAction
} = clientContactsSlice.actions;
export default clientContactsSlice.reducer;