import { FC, memo, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Text, DropdownMulti, Button, iconsTypes, Tabs, Col, Dropdown, Modal, setLocalStorage, getLocalStorage, clearLocalStorage } from "lavaa";
import { useReports } from "../../../Hooks/UseReports";
import { useAccount } from "../../../Hooks/UseAccount";
import { DischargeTCM } from "./TabbedTables/DischargeTCM/DischargeTCM.com";
import { AdmissionTCM } from "./TabbedTables/AdmissionTCM/AdmissionTCM.com";
import css from "./TcmReports.module.scss";
import { reportLinks } from "./TcmReports.data";
import { formatTimestamp } from "../../../Tools/date";

// TCM Report Names
const reportNames = [
    'ReportDischargeTCM', 
    'ReportAdmissionTCM'
];

// Column Filter Names
const columnFilterNames = [
    'ReportDischargeTCM_ColumnFilter', 
    'ReportAdmissionTCM_ColumnFilter'
];

// Page Titles
const pageTitles = [
    'Transitional Care Management',
    'Current Admissions'
];

const initialFilter = {
    page: 1,
    pageSize: 10,
    search: [],
    sort: {
        sortedByField: '',
        isAscending: true,
        sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
    },

};

// TcmReports Component
export const TcmReports: FC = memo(() => {

    // Constants
    const filterTypes: any = {
        contains: 0,
        dateRange: 1,
        numberRange: 2,
        startsWith: 3,
        endsWith: 4,
        equals: 5,
        notEqual: 6,
        notContains: 7,
        blank: 8,
        notBlank: 9,
        lessThan: 10,
        greaterThan: 11,
        lessThanOrEqual: 12,
        greaterThanOrEqual: 13,
        lessThanOrNull: 14,
        greaterThanOrNull: 15
    };

    // States
    const [currentReportName, setCurrentReportName] = useState(reportNames[0]);
    const [currentColumnFilterName, setCurrentColumnFilterName] = useState(columnFilterNames[0]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [filteredHeaders, setFilteredHeaders] = useState<any[]>([]);
    const [filteredHeadersLastUpdate, setFilteredHeadersLastUpdate] = useState(0);
    const [reset, setReset] = useState(0);
    const [rows, setRows] = useState([]);
    const [totalPages, setTotalPages] = useState(25);
    const [pageTitle, setPageTitle] = useState(pageTitles[0]);
    const [selectedAttributed, setSelectedAttributed] = useState<any>({name: 'All Patients', value: 'all'});
    const [attributedFilter, setAttributedFilter] = useState<any>([]);
    const [snoozeSearch, setSnoozeSearch] = useState<any>([
        {filteredByField: 'StartDateForSnooze', filterType: filterTypes.greaterThanOrNull, filterTerm: null, filterFrom: formatTimestamp(+new Date() + 3 * 60 * 1000), filterTo: null},
        {filteredByField: 'FinishDateForSnooze', filterType: filterTypes.lessThanOrNull, filterTerm: null, filterFrom: formatTimestamp(+new Date() - 3 * 60 * 1000), filterTo: null, operator: 1}
    ]);
    const [selectedSnooze, setSelectedSnooze] = useState({name: 'All Patients', value: 'all'});
    
    // Hooks
    const { exportLoading, fetchExcelReportsData, requestReports, getReport } = useReports();
    const { HMSProjectId, account } = useAccount();
    const { reportType = 'tcm', reportSubType = 'DischargeTCM' } = useParams();

    // Find initial report tab
    const reportLink: any = Object.values(reportLinks).find((reportLink: any) => reportLink.link === reportSubType);
    const initialTab = reportLink ? (reportLink?.tab ?? 0) : 0;

    // Table Filter
    const [filter, setFilter] = useState<any>(initialFilter);

    // Handle Toggle Column
    const handleToggleColumn = (data: any) => {
        console.log('Handle Toggle Column', data);
        
        const indexedData = data.map((item: any, index: number) => ({...item})).sort((a: any, b: any) => a.index - b.index);
        
        setFilteredHeaders(indexedData);
        setFilteredHeadersLastUpdate(performance.now());
        setLocalStorage(currentColumnFilterName, indexedData);
    };

    // Handle Reset Columns
    const handleResetColumns = () => {
        const indexedData: any = headers.map((item: any, index: number) => ({...item})).sort((a: any, b: any) => a.index - b.index);
        
        // Reset Column Filter
        setFilteredHeaders(indexedData.filter((header: any) => header.checked));
        setReset(performance.now());

        // Reset Attributed
        handleAttributedSelect({name: 'All Patients', value: 'all'});
    };

    // Handle Download Excel Report
    const handleDownloadExcelRepost = () => {
        if (exportLoading == false) {
            // const requestFilter = {search: filter.search, sort: filter.sort};
            fetchExcelReportsData(reportType, currentReportName, HMSProjectId, account.access_token, [...filter.search, ...snoozeSearch, ...attributedFilter]);
        }
    };

    // Handle Attributed Select
    const handleAttributedSelect = (data: any) => {
        const value = data.value; // all | true | false
        let attributedFilterUpdate: any[] = [];

        // Attributed
        if (value === true) {
            attributedFilterUpdate = [
                {filteredByField: 'IsAttributedPatient', filterType: filterTypes.equals, filterTerm: 'true', filterFrom: null, filterTo: null}
            ];
        }

        // Non Attributed
        if (value === false) {
            attributedFilterUpdate = [
                {filteredByField: 'IsAttributedPatient', filterType: filterTypes.equals, filterTerm: 'false', filterFrom: null, filterTo: null}
            ];
        }
        
        // Update States
        setAttributedFilter(attributedFilterUpdate);
        setSelectedAttributed(data);
    };

    // Handle Tab Change
    const handleTabChange = (tab: number) => {
        if (currentReportName !== reportNames[tab]) {
            setHeaders([]);
            setFilteredHeaders([]);
            setRows([]);
            setFilter({...initialFilter});
            setCurrentReportName(reportNames[tab]);
            setCurrentColumnFilterName(columnFilterNames[tab]);
            setPageTitle(pageTitles[tab]);
            setFilteredHeadersLastUpdate(performance.now());
        }
    };

    // Handle Init Headers Filter
    const handleHeadersFilter = (headers: any[]) => {
        console.log('Handle Headers Filter', headers);
        let storedColumnConfig = getLocalStorage(currentColumnFilterName) || [];

        if (storedColumnConfig.length === 0) {
            storedColumnConfig = null;
            clearLocalStorage([currentColumnFilterName]);
        }
        
        setHeaders(headers);
        setFilteredHeaders(storedColumnConfig || headers.filter((item) => item.checked));
        setReset(performance.now());
    };

    // Handle Filter Update
    const handleFilterUpdate = (filterData: any) => {
        console.log('Handle Filter Update', filterData);
        
        setFilter(filterData);
    };
    
    return (
        <Container paddingLeft="1rem" paddingRight="1rem" paddingTop="1.5rem" paddingBottom="1.5rem" grow="1" className={css.TcmReports}>

            {/* Page Header */}
            <Row alignitems="center" paddingBottom="1rem">
                
                {/* Page Title */}
                <Row grow="1" paddingRight="2rem">
                    <Text text={pageTitle} size="x5" bold={true} className={css.Title} ellipsis={true}/>
                </Row>

                {/* Column Filter */}
                <Row grow="0" paddingRight="2rem" alignitems="center">

                    {/* Attributed Patient */}
                    <Row paddingLeft="1rem">
                        <Dropdown
                            data={[{name: 'All Patients', value: 'all'}, {name: 'Attributed Patients', value: true}, {name: 'Non Attributed Patients', value: false}]}
                            displayField="name" 
                            selectField="value" 
                            selected={selectedAttributed}
                            onSelect={handleAttributedSelect}
                        />
                    </Row>

                    {/* Columns Filter Selector */}
                    <Row paddingLeft="1rem">
                        <DropdownMulti 
                            data={headers}
                            displayField="headerName" 
                            selectField="field" 
                            placeholder="Shown Columns" 
                            initialSelected={filteredHeaders} 
                            reset={reset}
                            onSelect={handleToggleColumn}
                        />
                    </Row>

                    {/* Reset Columns */}
                    <Row paddingLeft="1rem">
                        <Button text="Reset" variant="text" type="secondary" icon={iconsTypes.reset} onClick={handleResetColumns} />
                    </Row>
                </Row>

                {/* Export to Excel */}
                <Row>
                    <Button isLoading={exportLoading} size="default" onClick={handleDownloadExcelRepost}>Export to Excel</Button>
                </Row>
            </Row>

            {/* Tabs */}
            <Col grow="1">
                <Tabs onChange={handleTabChange} initialTab={initialTab}
                  content={[
                    {
                        title: 'Transitional Care Management',
                        content: <DischargeTCM setFilteredHeaders={handleHeadersFilter} filteredHeaders={filteredHeaders} filteredHeadersLastUpdate={filteredHeadersLastUpdate} onFilterUpdate={handleFilterUpdate} attributedFilter={attributedFilter} />,
                        scroll: true
                    },
                    {
                        title: 'Current Admissions',
                        content: <AdmissionTCM setFilteredHeaders={handleHeadersFilter} filteredHeaders={filteredHeaders} filteredHeadersLastUpdate={filteredHeadersLastUpdate} onFilterUpdate={handleFilterUpdate} attributedFilter={attributedFilter} />,
                        scroll: true,
                        disabled: false
                    }
                ]} />
            </Col>
        </Container>
    )
});