import React, {FC, useEffect, useState} from "react";
import {Col, Dropdown, Input, Row, Text, InputNumber} from 'lavaa';
import css from "./style.module.scss";
import {contactReasons} from "./ReportClientInfo.data";
import {ClientContactTimer} from "./ClientContactTimer.com";

export type InputsType = {
    reason: string,
    minutes?: number,
    notes: string
}

export const InputsDefault: InputsType = {
    reason: "",
    minutes: undefined,
    notes: ""
}

interface IProps {
    onInputChange: (data: InputsType) => void,
    onTimerStart: () => void,
    onTimerStop: (duration: number) => void,
    hasCreated: boolean
}

// Client Contact Form
const ClientContactForm: FC<IProps> = (props) => {

    const { onInputChange, onTimerStart, onTimerStop, hasCreated } = props;

    const [inputs, setInputs] = useState<InputsType>(InputsDefault);
    const [hasBeenSaved, setHasBeenSaved] = useState(false);
    const timerHasStartedRef = React.useRef(false);
    const durationRef = React.useRef(0);

    const reasonSelected = contactReasons.find((item: any) => item.id === inputs.reason);

    useEffect(() => {
        onInputChange(inputs);
    }, [inputs]);

    function selectReasonHandler(data: any){
        setInputs({...inputs, reason: data.id});
    }

    function onChangeMinutes(value: number){
        setInputs({...inputs, minutes: value});
    }

    function onChangeNotes(value: string){
        setInputs({...inputs, notes: value});
    }

    function timerStartHandler(){
        onTimerStart();

        const minutes = (durationRef.current > 0) ? Math.floor(durationRef.current / 60) : undefined;
        setInputs({...inputs, minutes: minutes});
        timerHasStartedRef.current = true;
        setHasBeenSaved(true);
    }

    function timerStopHandler(duration: number){
        onTimerStop(duration);

        durationRef.current = duration;
        const minutes = Math.floor(duration / 60);
        setInputs({...inputs, minutes: minutes});
    }

    const timerDisabled = !!(inputs.reason === "" || inputs.minutes);
    const dropdownDisabled = hasBeenSaved;
    const minutesDisabled = timerHasStartedRef.current || hasCreated;
    const hideTimer = (hasCreated && !timerHasStartedRef.current);

    return (
        <Col className={css.Contacts}>
            <Row className={css.FormRow} alignitems="center">
                <Row className={css.Label}><Text size="x2" bold>Reason*</Text></Row>
                <Row className={css.Value}>
                    <Dropdown
                        type="inline"
                        hasDelete={false}
                        size="tiny"
                        placeholder="Select"
                        hidePlaceholder={true}
                        data={ contactReasons }
                        selected={reasonSelected}
                        onSelect={selectReasonHandler}
                        className={css.Dropdown}
                        disabled={dropdownDisabled}
                    />
                </Row>
            </Row>
            <Row className={css.FormRow} alignitems="center">
                <Row className={css.Label}><Text size="x2" bold>Minutes</Text></Row>
                <Row className={css.Value}>
                    <InputNumber
                        onChange={onChangeMinutes}
                        value={inputs.minutes}
                        placeholder={"Type the time"}
                        min={1}
                        max={600}
                        step={1}
                        size="small"
                        className={css.InputNumber}
                        disabled={minutesDisabled}
                        key={timerHasStartedRef.current ? inputs.minutes : "minutes"}
                    />
                </Row>
            </Row>
            <Row className={css.FormRow} alignitems="start">
                <Row className={css.Label}><Text size="x2" bold>Notes</Text></Row>
                <Row className={css.Value}>
                    <Input
                        onChange={onChangeNotes}
                        value={inputs.notes}
                        placeholder={"Type here"}
                        size="small"
                        type="textarea"
                    />
                </Row>
            </Row>
            {
                !hideTimer && (
                    <ClientContactTimer
                        onStart={timerStartHandler}
                        onStop={timerStopHandler}
                        disabled={timerDisabled}
                        hasCreated={hasCreated}
                    />
                )
            }
        </Col>
    )
};

export { ClientContactForm };