import { FC } from 'react';
import { Row, Col, iconsTypes, Icon, TooltipWrapper } from 'lavaa';

type Props = {
    value: string;
    data: any;
    colDef: any;
};

// MeasureCellRenderer Component
export const MeasureCellRenderer: FC<Props> = (props: Props) => {
    const { value, data } = props;
    
    return (
        <Col grow="1" shrink="1" justifycontent="center" alignitems="center" style={{whiteSpace: 'normal'}}>
            
            {/* Icons */}
            <Row paddingBottom="0.25rem">
                {
                    value && data.TooltipMessage ? <TooltipWrapper tooltipText={data.TooltipMessage} positionV="top" positionH="right" whiteSpace="pre">
                        {value}
                    </TooltipWrapper> : value
                }
            </Row>
        </Col>
    )
};