import { FC } from 'react';
import { Row, iconsTypes, Button } from 'lavaa';

type Props = {
    value: string;
    data: any;
    colDef: any;
};

// ClientDetailsCellRenderer Component
export const ClientDetailsCellRenderer: FC<Props> = (props: Props) => {
    let { value, data = {}, colDef } = props;
    const { setClientDetailsId } = colDef;

    if (value === null) {
        return null;
    }

    const clientID = data.ClientID || data.clientid;
    const sourceClientID = data.SourceClientID || data.sourceclientid;

    return (
        // Client Details
        <Row grow="0" paddingRight="0.75rem" paddingLeft="0.75rem" alignitems="center">
            <Button onClick={() => setClientDetailsId(clientID, sourceClientID)} icon={iconsTypes.phoneOutline} type="secondary" variant="text" iconSize="18" />
        </Row>
    )
};