import React, {FC} from "react";
import {Icon, iconsTypes, Row, TooltipWrapper} from "lavaa";
import css from "./DiagnosisRisk.module.scss";
import {HeadersDataType, ResultsDataType} from "../Desktop/Results.data";

interface RiskType {
	[key: string]: {
		risk: number,
		modules: string[]
	}
}

interface IProps {
	results: ResultsDataType[],
	headers: HeadersDataType[]
}

// Diagnosis Risk
const DiagnosisRisk: FC<IProps> = (props) => {
	const {results, headers} = props;

	const modules = headers.filter((header) => header.dataType === "module");
	const risks: RiskType = {};

	modules.forEach((module) => {
		const icon = module.icon || "stack"; // Default icon

		if(!risks.hasOwnProperty(icon)) {
			risks[icon] = {
				risk: 0,
				modules: []
			};
		}

		const result = results.find((result) => result.moduleUID === module.columnName);
		if(!result) return;

		risks[icon].risk = Math.max(risks[icon].risk, result.resultPercentage);
		risks[icon].modules.push(result.moduleName);
	});

	const risksArray = Object.entries(risks).map(([icon, risk]) => {
		const riskNumber = risk.risk;
		return {
			icon,
			risk: riskNumber,
			riskLevel: getRiskLevel(riskNumber),
			modules: prepareModules(risk.modules)
		}
	});

	const risksSorted = risksArray.sort((a, b) => b.risk - a.risk);

	function getRiskLevel(risk: number): string {
		if(risk <= 25) return "no-risk";
		if(risk <= 50) return "medium-risk";
		if(risk <= 75) return "medium-high-risk";
		return "high-risk";
	}

	function prepareModules(modules: string[]): string {
		const maxLengthPerRow = 80;
		let result = "";
		let length = 0;
		modules.forEach((module, index) => {
			if(length + module.length > maxLengthPerRow) {
				result += "\n";
				length = 0;
			}

			if(index === modules.length - 1) {
				result += module;
				return;
			}
			else {
				result += module + ", ";
			}

			length += module.length;
		});

		return result;
	}
	
	return (
		<Row className={css.List} alignitems="center" justifycontent="start" grow="1">
			{
				risksSorted.map((risk) => {
					if(!iconsTypes.hasOwnProperty(risk.icon)) {
						return undefined
					}

					// @ts-ignore
					const icon = iconsTypes[risk.icon];

					return (
						<Row key={risk.icon} className={css.IconRisk} data-risk={risk.riskLevel}>
							{/* No Modules */}
							{
								risk.modules === "" ? null :
								<TooltipWrapper tooltipText={risk.modules} positionV="top" positionH="right" whiteSpace="pre">
									<Icon name={icon} size="x2" />
								</TooltipWrapper>
							}
						</Row>
					);
				})
			}
		</Row>
	);
};

export {DiagnosisRisk};
