import {ComplexFilterParamType, ComplexFilterParamSelectType, ComplexFilterOptionSelectType, ComplexFilterType, iconsTypes} from "lavaa";
import {PatientListTypeFilterByType} from "./PatientList.type";

export const filterTypes: any = {
	contains: 0,
	DateRange: 1,
	NumberRange: 2,
	startsWith: 3,
	endsWith: 4,
	equals: 5,
	notEqual: 6,
	notContains: 7,
	blank: 8,
	notBlank: 9
};

export type ComplexFilterParamTypeLocal = ComplexFilterParamType & ComplexFilterParamSelectTypeLocal;

export type ComplexFilterParamSelectTypeLocal = ComplexFilterParamSelectType & {
	options: ComplexFilterOptionSelectTypeLocal[];
};
export type ComplexFilterOptionSelectTypeLocal = ComplexFilterOptionSelectType & {
	filter: PatientListTypeFilterByType | PatientListTypeFilterByType[];
};

export const testData:ComplexFilterType = [
	[
		{
			id: "appointmentDate",
			title: "Appointment Date",
			hasSearch: false,
			type: 'select',
			options: [
				{
					id: "today",
					value: "Today",
					icon: iconsTypes.calendar,
					selected: false
				},
				{
					id: "tomorrow",
					value: "Tomorrow",
					icon: iconsTypes.calendar,
					selected: false
				},
				{
					id: "next7days",
					value: "Next 7 days",
					icon: iconsTypes.calendar,
					selected: false
				}
			]
		},
		{
			id: "gender",
			title: "Gender",
			hasSearch: false,
			type: 'multiselect',
			options: [
				{
					id: "male",
					value: "Male",
					selected: false
				},
				{
					id: "female",
					value: "Female",
					selected: false
				}
			]
		},
			{
				id: "age",
				title: "Age",
				hasSearch: true,
				type: 'select',
				options: [
					{
						id: "18-45",
						value: "18-45",
						selected: false
					},
					{
						id: "46-55",
						value: "46-55",
						selected: false
					},
					{
						id: "56-65",
						value: "56-65",
						selected: false
					},
					{
						id: "66-75",
						value: "66-75",
						selected: false
					},
					{
						id: "75+",
						value: "75+",
						selected: false
					}
				]
			},
			{
				id: "comparison",
				title: "Num comparison",
				type: 'conditions',
				label: "label",
				hasLess: true,
				hasLessEqual: true,
				hasGreater: true,
				hasGreaterEqual: true,
				hasEqual: true,
				hasBetween: true,
				hasBetweenEqual: true,
				selected: {
					comparison: undefined
				}
			}
		],
		[
			{
				id: "bmi",
				title: "BMI",
				hasSearch: false,
				type: 'multiselect',
				options: [
					{
						id: "underweight",
						value: "Underweight",
						icon: iconsTypes.lowRisk,
						selected: false
					},
					{
						id: "normalWeight",
						value: "Normal weight",
						icon: iconsTypes.mediumRisk,
						selected: false
					},
					{
						id: "overweight",
						value: "Overweight",
						icon: iconsTypes.highRisk,
						selected: false
					},
				]
			},
			{
				id: "date",
				title: "Date",
				type: 'date',
				selected: {
					startDate: null,
					endDate: null
				}
			},
	]
];

export const filterDataParams: {[key: string]: ComplexFilterParamTypeLocal} = {
	"gender": {
		id: "Gender",
		title: "Gender",
		hasSearch: false,
		type: 'select',
		options: [
			{
				id: "male",
				value: "Male",
				selected: false,
				filter: {
					filterField: "Gender",
					filteredByField: "Gender",
					filterParamId: "male",
					filterTerm: "male",
					filterFrom: null,
					filterTo: null,
					filterType: filterTypes.equals,
				}
			},
			{
				id: "female",
				value: "Female",
				selected: false,
				filter: {
					filterField: "Gender",
					filteredByField: "Gender",
					filterParamId: "female",
					filterTerm: "female",
					filterFrom: null,
					filterTo: null,
					filterType: filterTypes.equals,
				}
			}
		]
	},
	"dateofbirth": {
		id: "DateOfBirth",
		title: "Age",
		hasSearch: true,
		type: 'select',
		options: [
			{
				id: "18-45",
				value: "18-45",
				selected: false,
				filter: {
					filterField: "DateOfBirth",
					filteredByField: "DateOfBirth",
					filterParamId: "18-45",
					filterTerm: null,
					filterFrom: findDOB(46),
					filterTo: findDOB(17),
					filterType: filterTypes.DateRange,
				}
			},
			{
				id: "46-55",
				value: "46-55",
				selected: false,
				filter: {
					filterField: "DateOfBirth",
					filteredByField: "DateOfBirth",
					filterParamId: "46-55",
					filterTerm: null,
					filterFrom: findDOB(56),
					filterTo: findDOB(46),
					filterType: filterTypes.DateRange,
				}
			},
			{
				id: "56-65",
				value: "56-65",
				selected: false,
				filter: {
					filterField: "DateOfBirth",
					filteredByField: "DateOfBirth",
					filterParamId: "56-65",
					filterTerm: null,
					filterFrom: findDOB(66),
					filterTo: findDOB(56),
					filterType: filterTypes.DateRange,
				}
			},
			{
				id: "66-75",
				value: "66-75",
				selected: false,
				filter: {
					filterField: "DateOfBirth",
					filteredByField: "DateOfBirth",
					filterParamId: "66-75",
					filterTerm: null,
					filterFrom: findDOB(76),
					filterTo: findDOB(66),
					filterType: filterTypes.DateRange,
				}
			},
			{
				id: "75+",
				value: "75+",
				selected: false,
				filter: {
					filterField: "DateOfBirth",
					filteredByField: "DateOfBirth",
					filterParamId: "75+",
					filterTerm: null,
					filterFrom: null,
					filterTo: findDOB(76),
					filterType: filterTypes.DateRange,
				}
			}
		]
	},
	"bmi": {
		id: "BMI",
		title: "BMI",
		hasSearch: false,
		type: 'select',
		options: [
			{
				id: "underweight",
				value: "Underweight",
				icon: iconsTypes.lowRisk,
				selected: false,
				filter: {
					filterField: "BMI",
					filteredByField: "BMI",
					filterParamId: "underweight",
					filterTerm: null,
					filterFrom: null,
					filterTo: "18.5",
					filterType: filterTypes.NumberRange,
				}
			},
			{
				id: "normalWeight",
				value: "Normal weight",
				icon: iconsTypes.normalRisk,
				selected: false,
				filter: {
					filterField: "BMI",
					filteredByField: "BMI",
					filterParamId: "normalWeight",
					filterTerm: null,
					filterFrom: "18.5",
					filterTo: "24.9",
					filterType: filterTypes.NumberRange,
				}
			},
			{
				id: "overweight",
				value: "Overweight",
				icon: iconsTypes.mediumRisk,
				selected: false,
				filter: {
					filterField: "BMI",
					filteredByField: "BMI",
					filterParamId: "overweight",
					filterTerm: null,
					filterFrom: "25",
					filterTo: "29.9",
					filterType: filterTypes.NumberRange,
				}
			},
			{
				id: "obesity",
				value: "Obesity",
				icon: iconsTypes.highRisk,
				selected: false,
				filter: {
					filterField: "BMI",
					filteredByField: "BMI",
					filterParamId: "obesity",
					filterTerm: null,
					filterFrom: "30",
					filterTo: null,
					filterType: filterTypes.NumberRange,
				}
			},
		]
	},
	"bloodpressure": {
		id: "BloodPressure",
		title: "Blood Pressure",
		hasSearch: false,
		type: 'select',
		options: [
			{
				id: "low",
				value: "Low",
				icon: iconsTypes.lowRisk,
				selected: false,
				filter: [
					{
						filterField: "BloodPressure",
						filteredByField: "SystolicBloodPressure",
						filterParamId: "low",
						filterTerm: null,
						filterFrom: null,
						filterTo: "90",
						filterType: filterTypes.NumberRange,
					},
					{
						filterField: "BloodPressure",
						filteredByField: "DiastolicBloodPressure",
						filterParamId: "low",
						filterTerm: null,
						filterFrom: null,
						filterTo: "60",
						filterType: filterTypes.NumberRange,
					}
				]
			},
			{
				id: "normal",
				value: "Normal",
				icon: iconsTypes.normalRisk,
				selected: false,
				filter: [
					{
						filterField: "BloodPressure",
						filteredByField: "SystolicBloodPressure",
						filterParamId: "low",
						filterTerm: null,
						filterFrom: "60",
						filterTo: "120",
						filterType: filterTypes.NumberRange,
					},
					{
						filterField: "BloodPressure",
						filteredByField: "DiastolicBloodPressure",
						filterParamId: "low",
						filterTerm: null,
						filterFrom: "60",
						filterTo: "80",
						filterType: filterTypes.NumberRange,
					}
				]
			},
			{
				id: "elevated",
				value: "Elevated",
				icon: iconsTypes.highRisk,
				selected: false,
				filter: [
					{
						filterField: "BloodPressure",
						filteredByField: "SystolicBloodPressure",
						filterParamId: "low",
						filterTerm: null,
						filterFrom: "120",
						filterTo: null,
						filterType: filterTypes.NumberRange,
					},
					{
						filterField: "BloodPressure",
						filteredByField: "DiastolicBloodPressure",
						filterParamId: "low",
						filterTerm: null,
						filterFrom: "80",
						filterTo: null,
						filterType: filterTypes.NumberRange,
					}
				]
			},
		]
	},
}

function findDOB(age: number): string {
	const today = new Date();
	const birthYear = today.getFullYear() - age;
	const birthDate = new Date(birthYear, today.getMonth(), today.getDate());

	// Return format yyyy-mm-dd
	return birthDate.getFullYear() + "-" + (birthDate.getMonth() + 1) + "-" + birthDate.getDate();
}