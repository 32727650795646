import React, {FC} from "react";
import {Row, Col, Text, Paginator, Icon, iconsTypes, Loading} from "lavaa";
import {IClientType, IModuleType} from "../../Redux/Slices/Demo/Demo.data";
import css from "./PatientListMob.module.scss";
import {ModulesMenu} from "../Menu/ModulesMenu/ModulesMenu.component";
import {PatientCardMob} from "./Patient/Card.component";
import {useDemo} from "../../Hooks/useDemo";
import {SortByMob} from "./SortBy";
import {useParams} from "react-router-dom";
import {useAccount} from "../../Hooks/UseAccount";

interface IProps {
    onModuleSelect: (data: IModuleType[]) => void,
    modules: IModuleType[],
    clientsLoading: boolean
}

// Patients List Mobile
const PatientsListMob: FC<IProps> = (props) => {
    const {page, clients, setPage} = useDemo();
    const {onModuleSelect, modules, clientsLoading} = props;
    const currentItems = clients.length;


    useDemo(false, true);
    const {search} = useParams();

    const {HMSProjectId, account} = useAccount();
    const exportLink = `https://api.lavaa.health/getapprovedclients/${HMSProjectId}/${account.access_token}`;

    let title = "Health Priority";

    if(search) {
        title = `Search Results: ${search}`;
    }

    function changePage(page: number) {
        setPage(page - 1);
    }

    return (
        <Col grow="1" paddingLeft="0.5rem" paddingRight="0.5rem" className={css.ListWrapper}>
            <Row paddingTop="2rem">
                <Text text={title} ellipsis={true} size="x6" bold={true} className={css.Title}/>
            </Row>
            <Row className={css.ExportLink} paddingTop="2rem" alignitems="center">
                <Col className={css.ExportLinkIcon}>
                    <Icon name={iconsTypes.downloadDoc}/>
                </Col>
                <a href={exportLink}>Export approved patients</a>
            </Row>
            <Row paddingTop="2rem" className={css.FilterMob}>
                <Col paddingRight="0.25rem">
                    <ModulesMenu onModuleSelect={onModuleSelect}/>
                </Col>
                <Col paddingLeft="0.25rem">
                    <SortByMob modules={modules}/>
                </Col>
            </Row>

            <Col className={css.List} paddingTop="1.5rem">
                {
                    clients.map((client: IClientType) => {
                        return <PatientCardMob key={client.id} client={client} modules={modules}/>
                    })
                }
            </Col>
            {
                !clientsLoading ? null :
                    <Row className={css.Loading} alignitems="center" justifycontent="center">
                        <Loading/>
                    </Row>
            }

            {/* Paginator */}
            <Row paddingTop="2rem" paddingBottom="2rem" justifycontent="center">
                <Paginator page={page + 1} changePage={changePage} perPage={25} currentItems={currentItems}/>
            </Row>
        </Col>
    )
};

export {PatientsListMob};
