import React, {FC, useContext, useEffect} from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import {addSnackbar, Col, Icon, iconsTypes, Modal, Row, Text} from 'lavaa';
import {useAccount} from "../../../../Hooks/UseAccount";
import {useReports} from "../../../../Hooks/UseReports";
import {copyToClipboard} from "../../../../Tools/clipboard";
import {useClientContacts} from "../../../../Hooks/UseClientContacts";
import css from "./style.module.scss";
import {ClientContactForm, InputsDefault, InputsType} from "./ClientContactForm.com";

enum formStatuses {
    blank,
    timerStarted,
    timerStopped,
    saved
}

// Report Client Info Modal
const ReportClientInfo: FC<any> = () => {

    const { isReportClientInfoModalActive, setReportClientInfoModalActive, reportClientInfoId, setReportClientInfoId, reportSourceClientInfoId, setReportSourceClientInfoId } = useContext(ModalsCtx);
    const { HMSProjectId, account } = useAccount();
    const { getClientContacts, addClientContact, updateClientContact, addFullClientContact } = useClientContacts();

    const [showSaveButton, setShowSaveButton] = React.useState(false);
    const [formStatus, setFormStatus] = React.useState(formStatuses.blank);
    const inputsRef = React.useRef<InputsType>(InputsDefault);
    const timerRunRef = React.useRef({
        startTime: 0,
        duration: 0
    });

    const { getClientDetailsById, clientDetails, clearClientDetails } = useReports();

    useEffect(() => {
        if(!isReportClientInfoModalActive) return;
        if(reportClientInfoId === "") return;

        // Get Client Details
        getClientDetailsById(reportClientInfoId, HMSProjectId);
        getClientContacts(reportSourceClientInfoId, HMSProjectId);
    }, [HMSProjectId, isReportClientInfoModalActive, reportClientInfoId]);

    useEffect(() => {
        changeShowSaveBtn();
    }, [formStatus]);

    function changeShowSaveBtn(){
        let showSave = false;

        const savedStatus = [formStatuses.timerStopped, formStatuses.saved].includes(formStatus);
        if(savedStatus){
            showSave = true;
        }

        if(
            inputsRef.current.minutes !== undefined
            && inputsRef.current.reason !== "" &&
            [formStatuses.blank, formStatuses.saved].includes(formStatus)
        ){
            showSave = true;
        }

        console.log("showSave", showSave, formStatus);

        setShowSaveButton(showSave);
    }

    // Handle Close
    const handleClose = (strict = false) => {
        if(!strict && formStatus != formStatuses.blank){
            saveForm();
        }

        setReportClientInfoId("");
        setReportSourceClientInfoId("");
        clearClientDetails();
        setReportClientInfoModalActive(false);
        timerRunRef.current = {startTime: 0, duration: 0};
        setFormStatus(formStatuses.blank);
    };

    // Handle Copy
    async function handleCopy (value: string) {
        await copyToClipboard(value);
        addSnackbar('success', 'Copied!', '');
    }

    function onInputChange(data: InputsType){
        inputsRef.current = data;
        changeShowSaveBtn();
    }

    function onTimerStart(){
        console.log("Timer Start");
        timerRunRef.current.startTime = performance.now();
        if(formStatus === formStatuses.blank){
            addClientContact(reportClientInfoId, account.id, inputsRef.current.reason, inputsRef.current.notes);
        }
        setFormStatus(formStatuses.timerStarted);
    }

    function onTimerStop(duration: number){
        console.log("Timer Stop", duration);
        timerRunRef.current.duration = duration;
        updateClientContact(reportSourceClientInfoId, inputsRef.current.notes, duration);
        setFormStatus(formStatuses.timerStopped);
        handleClose();
        addSnackbar('success', 'Successfully saved', '');
    }

    function handleSave(){
        saveForm()

        if([formStatuses.timerStopped, formStatuses.saved].includes(formStatus)){
            handleClose(true);
        }
    }

    function saveForm(){
        if(formStatus === formStatuses.blank){
            addFullClientContact(reportClientInfoId, account.id, inputsRef.current.reason, inputsRef.current.minutes, inputsRef.current.notes);
            setFormStatus(formStatuses.saved);
            handleClose(true);
        }
        else{
            let duration = inputsRef.current.minutes ? inputsRef.current.minutes * 60 : undefined;

            // If timer is running - save current time
            if(formStatus === formStatuses.timerStarted){
                const now = performance.now();
                duration = Math.floor((now - timerRunRef.current.startTime) / 1000) + timerRunRef.current.duration;
            }

            updateClientContact(reportSourceClientInfoId, inputsRef.current.notes, duration);
        }
        addSnackbar('success', 'Successfully saved', '');
    }

    return <Modal 
        active={ isReportClientInfoModalActive }
        onClose={ handleClose }
        onOk={ handleSave }
        title="Reach out to the Patient"
        okText="Save"
        okDisabled={!showSaveButton}
        cancelDisabled={true}
        type="compact"
    >
        <Col>
            {/* Phones */}
            <Row className={css.TextRow}>
                <Row className={css.Label}>Phones:</Row>
                <Col className={css.Value}>
                    {
                        clientDetails.phones.length ? clientDetails.phones.map((phone: string, index: number) => (
                            <Row key={index} alignitems="center">
                                <Text ellipsis={true}>
                                    <a href={`tel:${phone}`}>{phone}</a>
                                </Text>
                                <span className={css.Copy} onClick={() => handleCopy(phone)}>
                                    <Icon name={iconsTypes.copy}/>
                                </span>
                            </Row>
                        )) : 'No data'
                    }
                </Col>
            </Row>

            {/* Email */}
            <Row className={css.TextRow}>
                <Row className={css.Label}>Email:</Row>
                <Col className={css.Value}>
                    {
                        clientDetails.email.length ? clientDetails.email.map((email: string, index: number) => (
                            <Row key={index} alignitems="center">
                                <Text ellipsis={true}>
                                    <a href={`mailto:${email}`}>{email}</a>
                                </Text>
                                <span className={css.Copy} onClick={() => handleCopy(email)}>
                                    <Icon name={iconsTypes.copy}/>
                                </span>
                            </Row>
                        )) : 'No data'
                    }
                </Col>
            </Row>

            {/* Address */}
            <Row>
                <Row className={css.Label}>Address:</Row>
                <Col className={css.Value}>
                    {
                        clientDetails.address .length ?
                            (
                                <Row alignitems="start">
                                    <Text ellipsis={true} maxLines={2} style={{display: '-webkit-inline-box'}}>
                                        {clientDetails.address || 'No data'}
                                    </Text>
                                    <span  className={css.Copy} onClick={() => handleCopy(clientDetails.address)}>
                                        <Icon name={iconsTypes.copy}/>
                                    </span>
                                </Row>
                            ) : 'No data'
                    }
                </Col>
            </Row>
            <ClientContactForm
                onInputChange={onInputChange}
                onTimerStart={onTimerStart}
                // onTimerStop={onTimerStop}
                onTimerStop={() => handleClose()}
                hasCreated={formStatus !== formStatuses.blank}
            />
        </Col>
    </Modal>
};

export { ReportClientInfo };