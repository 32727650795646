import { FC } from 'react';
import { Row, Col, Text } from 'lavaa';
import { LinkCellRenderer } from '../LinkCellRenderer/LinkCellRenderer.com';

type Props = {
    value: string;
    data: any;
    colDef: any;
};

// PatientCellRenderer Component
export const PatientCellRenderer: FC<Props> = (props: Props) => {
    let { value, data = {}, colDef } = props;

    if (value === null) {
        return null;
    }
    
    const [PatientId, FullName] = value.split('~');
    
    return (
        <Row grow="1">
            <Col grow="1" shrink="1" justifycontent="center" alignitems="start" style={{whiteSpace: 'normal'}}>
                
                {/* Id */}
                <Row paddingBottom="0.25rem">
                    <Text size="x2" style={{textAlign: 'center'}}>
                        <LinkCellRenderer 
                            value={PatientId} 
                            colDef={{...colDef, url: data.EMRLink ? data.EMRLink : `/patients/${PatientId}`, target: data.EMRLink ? '_blank' : '_self'}}
                            data={data}
                        />
                    </Text>
                </Row>

                {/* Patient Full Name */}
                <Row>
                    <Text size="x2" style={{textAlign: 'center'}}>
                        <LinkCellRenderer 
                            value={FullName} 
                            colDef={{...colDef, url: `/patients/${PatientId}`, target: '_self'}}
                            data={data}
                        />
                    </Text>
                </Row>
            </Col>
        </Row>
    )
};