import { FC } from "react";
import { useParams } from "react-router-dom";
import { Row, Container, Splitter, Grid } from "lavaa";
import { ReportsMenu } from "../Menu/ReportsMenu/ReportsMenu.component";
import { GeneralReports } from "./General/GeneralReports.component";
import { CCMReports } from "./CCM/CCMReports.com";
import { AWVReports } from "./AWV/AWVReports.com";
import { DailyHuddleReports } from "./DailyHuddle/DailyHuddle.com";
import { TcmReports } from "./TCM/TcmReports.com";
import { QrdaReports } from "./Qrda/Qrda.com";
import { MissingCodes } from "./MissingCodes/MissingCodes.com";
// import { BigCalendar } from "../BigCalendar/BigCalendar.com";
import { useAccount } from "../../Hooks/UseAccount";

// Reports
const Reports: FC = () => {

    // Props
    // const {deviceType} = useContext(AppCtx);

    // Hooks
    const { reportType = 'generalReport' } = useParams();
    const { availableReports } = useAccount();
        
    return !availableReports ? null : (
        <Row grow="1">
            <Splitter resetLayoutKey={0} direction="vertical" collapsible={[0]} paneSizes={['22%', '78%']} name="ReportsLayout">
                
                {/* Left */}
                <Grid scroll="true">
                    <ReportsMenu activeReportType={reportType} />
                </Grid>

                {/* Center */}
                <Grid>

                    {/* Patient Care Prioritization Overview Report New */}
                    {reportType === 'generalReport' ? <GeneralReports /> : null}

                    {/* CCM Reports New */}
                    {reportType === 'ccmReport' ? <CCMReports /> : null}

                    {/* AWV Report */}
                    {reportType === 'awvReport' ? <AWVReports /> : null}

                    {/* Daily Huddle */}
                    {reportType === 'dailyHuddle' ? <DailyHuddleReports /> : null}

                    {/* TCM */}
                    {reportType === 'tcm' ? <TcmReports /> : null}

                    {/* QRDA */}
                    {reportType === 'qrda' ? <QrdaReports /> : null}

                    {/* Missing Codes */}
                    {reportType === 'missingCodes' ? <MissingCodes /> : null}

                    {/* Big Calendar */}
                    {/* {reportType === 'calendar' ? <BigCalendar /> : null} */}
                </Grid>
            </Splitter>
        </Row>
    )
};

export { Reports };
